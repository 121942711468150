'use client';

import { useUser } from '@auth0/nextjs-auth0/client';
import { usePathname, useRouter } from 'next/navigation';
import { useEffect, useMemo } from 'react';

import checkRestrictedRoute from '@/utils/checkRestrictedRoute';

export default function useAuth() {
  const { user, error, isLoading } = useUser();
  const { push } = useRouter();
  const pathname = usePathname();

  // Redirects if user is on restricted page without valid auth
  useEffect(() => {
    if (isLoading) return;

    if ((!user || error) && checkRestrictedRoute(pathname)) {
      push('/auth');
    }
  }, [isLoading, user, pathname, error, push]);

  return useMemo(() => ({ isLoading, user }), [user, isLoading]);
}
